// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconDocument(props) {
  var __color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Document";
  var color = __color !== undefined ? __color : "Teal";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              viewBoxSize: "40",
              className: props.className,
              children: JsxRuntime.jsx("g", {
                    children: JsxRuntime.jsx("g", {
                          children: JsxRuntime.jsxs("g", {
                                children: [
                                  JsxRuntime.jsx("rect", {
                                        height: "40",
                                        width: "40",
                                        x: "0",
                                        y: "0"
                                      }),
                                  JsxRuntime.jsx("path", {
                                        d: "M6,2 L6,38 L34,38 L34,4.81707572 L31.2370988,2 L6,2 Z M6,0 L31.2370988,0 C31.7742634,0 32.288848,0.216080302 32.6649753,0.599582666 L35.4278765,3.41665839 C35.7945813,3.79055345 36,4.29336788 36,4.81707572 L36,38 C36,39.1045695 35.1045695,40 34,40 L6,40 C4.8954305,40 4,39.1045695 4,38 L4,2 C4,0.8954305 4.8954305,0 6,0 Z",
                                        fill: "#98A0B0"
                                      }),
                                  JsxRuntime.jsx("rect", {
                                        fill: Icon.mapColor(color),
                                        rx: "1",
                                        x: "12",
                                        y: "22"
                                      }),
                                  JsxRuntime.jsx("path", {
                                        d: "M14.2585621,12.9756455 C13.8372562,12.9854506 13.4436887,12.766293 13.2301543,12.4029779 C13.0166199,12.0396628 13.0166199,11.5891903 13.2301543,11.2258752 C13.4436887,10.86256 13.8372562,10.6434025 14.2585621,10.6532076 L16.9759339,10.6532076 C17.2614482,10.6433696 17.4878198,10.4090635 17.4878198,10.1233798 C17.4878198,9.83769614 17.2614482,9.60339004 16.9759339,9.59355203 L15.5609636,9.59355203 L15.5609636,8.51188585 C15.5511256,8.22637163 15.3168195,8 15.0311359,8 C14.7454522,8 14.5111461,8.22637163 14.5013081,8.51188585 L14.5013081,9.59355203 L14.2585621,9.59355203 C13.4562128,9.58005 12.7089653,10.0003685 12.3038423,10.6930604 C11.8987192,11.3857523 11.8987192,12.2431008 12.3038423,12.9357927 C12.7089653,13.6284846 13.4562128,14.0488031 14.2585621,14.035301 L15.7993075,14.035301 C16.2206135,14.0254959 16.614181,14.2446535 16.8277153,14.6079686 C17.0412497,14.9712838 17.0412497,15.4217562 16.8277153,15.7850713 C16.614181,16.1483865 16.2206135,16.3675441 15.7993075,16.357739 L13.0322545,16.357739 C12.7467403,16.367577 12.5203687,16.6018831 12.5203687,16.8875667 C12.5203687,17.1732504 12.7467403,17.4075565 13.0322545,17.4173945 L14.4981637,17.4173945 L14.4981637,18.5210713 C14.5080017,18.8065855 14.7423078,19.0329572 15.0279915,19.0329572 C15.3136752,19.0329572 15.5479813,18.8065855 15.5578193,18.5210713 L15.5578193,17.4180234 L15.8269781,17.4180234 C17.0397896,17.3917166 18.0071735,16.3972241 17.9999599,15.1841487 C17.9927464,13.9710733 17.0136039,12.9881558 15.8005653,12.9762744 L14.2585621,12.9756455 Z",
                                        fill: Icon.mapColor(color)
                                      }),
                                  JsxRuntime.jsx("rect", {
                                        height: "2",
                                        width: "16",
                                        fill: Icon.mapColor(color),
                                        rx: "1",
                                        x: "12",
                                        y: "26"
                                      }),
                                  JsxRuntime.jsx("rect", {
                                        height: "2",
                                        width: "10",
                                        fill: Icon.mapColor(color),
                                        rx: "1",
                                        x: "12",
                                        y: "30"
                                      })
                                ]
                              }),
                          fillRule: "nonzero"
                        }),
                    fill: "none",
                    fillRule: "evenodd",
                    stroke: "none",
                    strokeWidth: "1"
                  })
            });
}

var make = IconDocument;

export {
  make ,
}
/* Icon Not a pure module */
