// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Project from "../../../../models/Project.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Uploader from "../../../../styleguide/forms/Uploader/Uploader.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ProjectNavbar from "../_components/ProjectNavbar/ProjectNavbar.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as ProjectStickyBar from "../_components/ProjectStickyBar/ProjectStickyBar.res.js";
import * as ProjectTopNavbar from "../_components/ProjectTopNavbar/ProjectTopNavbar.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as IconAdditionalUpload from "../../../../styleguide/icons/IconAdditionalUpload.res.js";
import * as ProviderProjectProposalFormScss from "./ProviderProjectProposalForm.scss";
import * as ProviderProjectProposalForm_DescriptionSection from "./ProviderProjectProposalForm_DescriptionSection.res.js";
import * as ProviderProjectProposalForm_AdditionalDocsSection from "./ProviderProjectProposalForm_AdditionalDocsSection.res.js";
import * as ProviderProjectProposalForm_SelectProviderSection from "./ProviderProjectProposalForm_SelectProviderSection.res.js";
import * as ProviderProjectProposalForm_UploadProposalDocumentSection from "./ProviderProjectProposalForm_UploadProposalDocumentSection.res.js";

var css = ProviderProjectProposalFormScss;

function reducer(state, action) {
  switch (action.TAG) {
    case "SetProvider" :
        return {
                providerId: Caml_option.some(action._0),
                document: state.document,
                additionalDocuments: state.additionalDocuments,
                deletedDocumentIds: state.deletedDocumentIds,
                expirationDate: state.expirationDate,
                description: state.description,
                proposalStatus: state.proposalStatus,
                filename: state.filename
              };
    case "SetExpirationDate" :
        return {
                providerId: state.providerId,
                document: state.document,
                additionalDocuments: state.additionalDocuments,
                deletedDocumentIds: state.deletedDocumentIds,
                expirationDate: Caml_option.some(action._0),
                description: state.description,
                proposalStatus: state.proposalStatus,
                filename: state.filename
              };
    case "SetDocument" :
        return {
                providerId: state.providerId,
                document: action._0,
                additionalDocuments: state.additionalDocuments,
                deletedDocumentIds: state.deletedDocumentIds,
                expirationDate: state.expirationDate,
                description: state.description,
                proposalStatus: state.proposalStatus,
                filename: undefined
              };
    case "AddDocument" :
        return {
                providerId: state.providerId,
                document: state.document,
                additionalDocuments: Belt_Array.concat(state.additionalDocuments, [action._0]),
                deletedDocumentIds: state.deletedDocumentIds,
                expirationDate: state.expirationDate,
                description: state.description,
                proposalStatus: state.proposalStatus,
                filename: state.filename
              };
    case "RemoveDocument" :
        var $$document = action._0;
        return {
                providerId: state.providerId,
                document: state.document,
                additionalDocuments: Belt_Array.keep(state.additionalDocuments, (function (stateDocuments) {
                        return stateDocuments.name !== $$document.name;
                      })),
                deletedDocumentIds: state.deletedDocumentIds,
                expirationDate: state.expirationDate,
                description: state.description,
                proposalStatus: state.proposalStatus,
                filename: state.filename
              };
    case "RemoveAlreadUploadedDocument" :
        return {
                providerId: state.providerId,
                document: state.document,
                additionalDocuments: state.additionalDocuments,
                deletedDocumentIds: Belt_Array.concat(state.deletedDocumentIds, [action._0.id]),
                expirationDate: state.expirationDate,
                description: state.description,
                proposalStatus: state.proposalStatus,
                filename: state.filename
              };
    case "SetDescription" :
        return {
                providerId: state.providerId,
                document: state.document,
                additionalDocuments: state.additionalDocuments,
                deletedDocumentIds: state.deletedDocumentIds,
                expirationDate: state.expirationDate,
                description: action._0,
                proposalStatus: state.proposalStatus,
                filename: state.filename
              };
    case "SetProposalStatus" :
        return {
                providerId: state.providerId,
                document: state.document,
                additionalDocuments: state.additionalDocuments,
                deletedDocumentIds: state.deletedDocumentIds,
                expirationDate: state.expirationDate,
                description: state.description,
                proposalStatus: action._0,
                filename: state.filename
              };
    
  }
}

function isSendProposalDisabled(state) {
  if (state.proposalStatus === "Submitting") {
    return true;
  }
  var match = state.providerId;
  var match$1 = state.document;
  var match$2 = state.expirationDate;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return match$2 === undefined;
    } else if (match$2 !== undefined && Belt_Option.isSome(state.filename)) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

function ProviderProjectProposalForm(props) {
  var projectStickyBarData = props.projectStickyBarData;
  var viewContext = props.viewContext;
  var proposal = props.proposal;
  var providers = props.providers;
  var projectId = props.projectId;
  var stickyBarUserRole = Project.Role.toRole(projectStickyBarData.userProjectRole);
  var initialState_providerId = providers.length === 1 ? Caml_option.some(Belt_Array.getExn(providers, 0).id) : undefined;
  var initialState_additionalDocuments = [];
  var initialState_deletedDocumentIds = [];
  var initialState_expirationDate = proposal !== undefined ? Caml_option.some(proposal.expirationDate) : undefined;
  var initialState_description = proposal !== undefined ? proposal.description : "";
  var initialState_filename = proposal !== undefined ? proposal.filename : undefined;
  var initialState = {
    providerId: initialState_providerId,
    document: undefined,
    additionalDocuments: initialState_additionalDocuments,
    deletedDocumentIds: initialState_deletedDocumentIds,
    expirationDate: initialState_expirationDate,
    description: initialState_description,
    proposalStatus: "Creating",
    filename: initialState_filename
  };
  var proposalId = proposal !== undefined ? Caml_option.some(proposal.id) : undefined;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          var match = state.proposalStatus;
          if (match === "Submitting") {
            if (proposalId !== undefined) {
              $$Promise.wait(Api.updateProjectProposal(Caml_option.valFromOption(proposalId), Belt_Option.getExn(state.providerId), projectId, state.document, Belt_Option.getExn(state.expirationDate), state.description, state.additionalDocuments, state.deletedDocumentIds), (function (x) {
                      if (x.TAG === "Ok") {
                        return Url.visit(Routes_Project.Proposal.show(projectId, x._0));
                      } else {
                        return SentryLogger.error1({
                                    rootModule: "ProviderProjectProposalForm",
                                    subModulePath: /* [] */0,
                                    value: "make",
                                    fullPath: "ProviderProjectProposalForm.make"
                                  }, "UpdateProposal::Error", [
                                    "Error",
                                    x._0
                                  ]);
                      }
                    }));
            } else {
              $$Promise.wait(Api.createNewProjectProposal(Belt_Option.getExn(state.providerId), projectId, Belt_Option.getExn(state.document), Belt_Option.getExn(state.expirationDate), state.description, state.additionalDocuments), (function (x) {
                      if (x.TAG === "Ok") {
                        return Url.visit(Routes_Project.Proposal.show(projectId, x._0));
                      } else {
                        return SentryLogger.error1({
                                    rootModule: "ProviderProjectProposalForm",
                                    subModulePath: /* [] */0,
                                    value: "make",
                                    fullPath: "ProviderProjectProposalForm.make"
                                  }, "CreateNewProposal::Error", [
                                    "Error",
                                    x._0
                                  ]);
                      }
                    }));
            }
          }
          
        }), [state.proposalStatus]);
  var tmp;
  tmp = stickyBarUserRole === "Visitor" ? null : JsxRuntime.jsx(ProviderProjectProposalForm_SelectProviderSection.make, {
          providers: providers,
          selectedProvider: state.providerId,
          selectProvider: (function (providerId) {
              dispatch({
                    TAG: "SetProvider",
                    _0: providerId
                  });
            }),
          disabled: Belt_Option.isSome(proposalId)
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ProjectTopNavbar.make, {
                      viewContext: viewContext,
                      userRole: props.userRole,
                      projectStickyBarData: projectStickyBarData
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h1", {
                              children: props.projectBusinessName + (": " + props.projectTitle),
                              className: css.headingText
                            }),
                        JsxRuntime.jsx(ProjectNavbar.make, {
                              activeTab: "Proposals",
                              userRole: stickyBarUserRole,
                              projectId: projectId,
                              viewContext: viewContext
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("h1", {
                                      children: proposalId !== undefined ? "Edit Proposal" : "Send Proposal",
                                      className: Cx.cx([
                                            css.headingText,
                                            css.sendProposalText
                                          ])
                                    }),
                                tmp,
                                JsxRuntime.jsx(ProviderProjectProposalForm_UploadProposalDocumentSection.make, {
                                      document: state.document,
                                      selectDocument: (function ($$document) {
                                          dispatch({
                                                TAG: "SetDocument",
                                                _0: Caml_option.some($$document)
                                              });
                                        }),
                                      removeDocument: (function (param) {
                                          dispatch({
                                                TAG: "SetDocument",
                                                _0: undefined
                                              });
                                        }),
                                      setExpirationDate: (function (date) {
                                          dispatch({
                                                TAG: "SetExpirationDate",
                                                _0: date
                                              });
                                        }),
                                      expirationDate: state.expirationDate,
                                      filename: state.filename,
                                      userRole: stickyBarUserRole,
                                      providers: providers
                                    }),
                                JsxRuntime.jsx(ProviderProjectProposalForm_DescriptionSection.make, {
                                      description: state.description,
                                      setDescription: (function (description) {
                                          dispatch({
                                                TAG: "SetDescription",
                                                _0: description
                                              });
                                        })
                                    }),
                                JsxRuntime.jsx(ProviderProjectProposalForm_AdditionalDocsSection.make, {
                                      alreadyUploadedDocuments: Belt_Array.keep(props.alreadyUploadedDocuments, (function ($$document) {
                                              return !Js_array.includes($$document.id, state.deletedDocumentIds);
                                            })),
                                      documents: state.additionalDocuments,
                                      removeAlreadyUploadedDocument: (function ($$document) {
                                          dispatch({
                                                TAG: "RemoveAlreadUploadedDocument",
                                                _0: $$document
                                              });
                                        }),
                                      removeDocument: (function ($$document) {
                                          dispatch({
                                                TAG: "RemoveDocument",
                                                _0: $$document
                                              });
                                        })
                                    }),
                                JsxRuntime.jsx(Uploader.make, {
                                      allowed: [
                                        "Pdf",
                                        "Doc",
                                        "Docx"
                                      ],
                                      multiple: true,
                                      className: css.additionalUploader,
                                      upload: (function (files) {
                                          Belt_Array.map(files, (function (file) {
                                                  var match = Uploader.FileType.fromMime(file.type);
                                                  switch (match) {
                                                    case "Jpg" :
                                                    case "Png" :
                                                    case "Avi" :
                                                    case "Mp4" :
                                                    case "Mov" :
                                                    case "Wmv" :
                                                        return ;
                                                    default:
                                                      return dispatch({
                                                                  TAG: "AddDocument",
                                                                  _0: file
                                                                });
                                                  }
                                                }));
                                        }),
                                      setUploadHandler: (function (prim) {
                                          
                                        }),
                                      children: JsxRuntime.jsxs(Button.make, {
                                            size: "SM",
                                            color: "Teal",
                                            buttonClassName: css.uploadDocumentButton,
                                            children: [
                                              JsxRuntime.jsx(IconAdditionalUpload.make, {
                                                    size: "MD",
                                                    color: "White"
                                                  }),
                                              "Upload Document"
                                            ]
                                          })
                                    }),
                                JsxRuntime.jsxs(Button.make, {
                                      size: "MD",
                                      color: "Primary",
                                      disabled: isSendProposalDisabled(state),
                                      visuallyDisabled: isSendProposalDisabled(state),
                                      className: css.sendProposalButton,
                                      onClick: (function (param) {
                                          dispatch({
                                                TAG: "SetProposalStatus",
                                                _0: "Submitting"
                                              });
                                        }),
                                      children: [
                                        proposalId !== undefined ? "Save Changes" : "Send Proposal",
                                        state.proposalStatus === "Submitting" ? JsxRuntime.jsx("span", {
                                                children: JsxRuntime.jsx(Spinner.make, {
                                                      size: "MD",
                                                      color: "White",
                                                      centered: false
                                                    }),
                                                className: css.inlineSpacer
                                              }) : null
                                      ]
                                    })
                              ],
                              className: css.colLeft
                            })
                      ],
                      className: css.contentWrapper
                    }),
                JsxRuntime.jsx(ProjectStickyBar.make, {
                      userRole: stickyBarUserRole,
                      projectStickyBarData: projectStickyBarData,
                      canSendProposals: true,
                      viewContext: viewContext,
                      desktop: props.desktop,
                      tablet: props.tablet,
                      mobile: props.mobile
                    })
              ],
              className: css.container
            });
}

var make = ProviderProjectProposalForm;

export {
  css ,
  reducer ,
  isSendProposalDisabled ,
  make ,
}
/* css Not a pure module */
