// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Provider from "../../../../models/Provider.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as ProjectProposal from "../../../../models/ProjectProposal.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectsViewContext from "../../../../models/ProjectsViewContext.res.js";
import * as ProjectStickyBarData from "../_components/ProjectStickyBar/ProjectStickyBarData.res.js";
import * as ProviderProjectProposalForm from "./ProviderProjectProposalForm.res.js";

function ProviderProjectProposalForm__JsBridge$default(props) {
  var railsContext = props.railsContext;
  var jsProps = props.jsProps;
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var proposal = React.useMemo((function () {
          return Belt_Option.map(Caml_option.nullable_to_opt(jsProps.proposal), ProjectProposal.ForForm.fromJson);
        }), [jsProps.proposal]);
  var providers = React.useMemo((function () {
          return Belt_Array.map(jsProps.providers, (function (provider) {
                        return Provider.Simple.fromJs(provider);
                      }));
        }), [jsProps.providers]);
  var additionalDocuments = React.useMemo((function () {
          return Belt_Array.map(jsProps.additionalDocuments, Project.$$Document.fromJs);
        }), [jsProps.additionalDocuments]);
  var viewContext = React.useMemo((function () {
          return ProjectsViewContext.fromString(jsProps.viewContext);
        }), [jsProps.viewContext]);
  var projectStickyBarData = React.useMemo((function () {
          return ProjectStickyBarData.fromJson(jsProps.projectStickyBar);
        }), [jsProps.projectStickyBar]);
  return JsxRuntime.jsx(ProviderProjectProposalForm.make, {
              projectId: Project.Id.fromJson(jsProps.projectId),
              projectTitle: jsProps.projectTitle,
              projectBusinessName: jsProps.projectBusinessName,
              providers: providers,
              proposal: proposal,
              alreadyUploadedDocuments: additionalDocuments,
              userRole: railsContext$1.userRole,
              viewContext: viewContext,
              projectStickyBarData: projectStickyBarData,
              desktop: railsContext$1.desktop,
              tablet: railsContext$1.tablet,
              mobile: railsContext$1.mobile
            });
}

var $$default = ProviderProjectProposalForm__JsBridge$default;

export {
  $$default as default,
}
/* react Not a pure module */
