// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../libs/ID.res.js";
import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderProjectProposalFormScss from "./ProviderProjectProposalForm.scss";

var css = ProviderProjectProposalFormScss;

function ProviderProjectProposalForm_SelectProviderSection(props) {
  var selectProvider = props.selectProvider;
  var selectedProvider = props.selectedProvider;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Select Provider",
                      className: css.secondaryHeadingText
                    }),
                JsxRuntime.jsx("p", {
                      children: "You can select only among providers approved by the client.",
                      className: css.paragraphText
                    }),
                JsxRuntime.jsxs(Select.make, {
                      id: "project-form-select-provider",
                      value: selectedProvider !== undefined ? ID.toString(Caml_option.valFromOption(selectedProvider)) : "",
                      disabled: props.disabled,
                      onChange: (function ($$event) {
                          selectProvider(ID.fromString($$event.target.value));
                        }),
                      children: [
                        JsxRuntime.jsx(Select.DefaultOption.make, {
                              children: "Select Provider..."
                            }),
                        Belt_Array.map(props.providers, (function (provider) {
                                return JsxRuntime.jsx(Select.$$Option.make, {
                                            value: ID.toString(provider.id),
                                            children: provider.name
                                          }, provider.name);
                              }))
                      ]
                    }),
                JsxRuntime.jsx("p", {
                      children: "IMPORTANT: The provider can't be changed once the proposal is submitted.",
                      className: css.notEditableMessage
                    })
              ],
              className: css.selectProviderSectionContainer
            });
}

var make = ProviderProjectProposalForm_SelectProviderSection;

export {
  css ,
  make ,
}
/* css Not a pure module */
